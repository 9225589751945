/**
 * A single rule where a subject is compared against a given value on an SDK.
 *
 * @typedef {object} CriteriaRule
 * @property {string} id - The rule GUID
 * @property {string} key - The short form key
 * @property {string} [target] - The secondary target value, used by Interaction Response Targeting
 * @property {string} group - The group type for the rule
 * @property {string} [suffix] - The last portion of the key for determining rule details
 * @property {string} name - The human readable label for the rule
 * @property {string} [comparator] - The comparator to be used for the rule
 * @property {object[]|undefined} details - The collection of sub rule when the type is `parent`
 * @property {string|number|boolean} value - The rule value
 * @property {string} type - The type used for helping determining the final comparator for the rule
 * @property {string} [parent] - The parent rule GUID if : "a32896b3-e448-4174-45e0-a49d7dda594c"
 * @example <caption>Event Rule</caption>
 * const event_rule = {
 *   id: 'b0ed560b-6f01-46cf-d869-b02a0734de36',
 *   group: 'code_point',
 *   key: 'local#app#RP_1',
 *   type: 'parent',
 *   details: [
 *     {
 *       id: '1cc2e829-c234-465b-b2d7-77a35e6d1901',
 *       type: 'number',
 *       comparator: '$eq',
 *       suffix: 'invokes/total',
 *       value: 1,
 *       parent: 'b0ed560b-6f01-46cf-d869-b02a0734de36',
 *     },
 *     {
 *       id: '4d0e40e5-e8f8-4ce6-82d6-13277250dc4d',
 *       type: 'number',
 *       comparator: '$ne',
 *       suffix: 'invokes/version',
 *       value: 2,
 *       parent: 'b0ed560b-6f01-46cf-d869-b02a0734de36',
 *     },
 *     {
 *       id: '2c007671-3b22-4e63-2011-fcdaf0a96866',
 *       type: 'duration',
 *       comparator: '$before',
 *       suffix: 'last_invoked_at/total',
 *       value: 540,
 *       parent: 'b0ed560b-6f01-46cf-d869-b02a0734de36',
 *     },
 *   ],
 * };
 * @example <caption>Interaction Rule</caption>
 * const interaction_rule = {
 *   id: '147f49d6-c473-4cbe-d6d8-92aa1590c47d',
 *   group: 'interactions',
 *   key: '55dbb2783ceaca52da000025',
 *   type: 'parent',
 *   details: [
 *     {
 *       id: '253da75a-efb5-4445-01b0-46f3ab7e887e',
 *       type: 'number',
 *       comparator: '$lt',
 *       suffix: 'invokes/total',
 *       value: 4,
 *       parent: '147f49d6-c473-4cbe-d6d8-92aa1590c47d',
 *     },
 *     {
 *       id: '88f4830b-2014-4c7d-4659-77eaf23e61d0',
 *       type: 'number',
 *       comparator: '$gt',
 *       suffix: 'invokes/version',
 *       value: 5,
 *       parent: 'c2925792-adb8-4adc-1d04-ba2bc5f3431f',
 *     },
 *     {
 *       id: '93dcf9fd-36c3-4bf9-3b1d-0bb556aafb3f',
 *       type: 'duration',
 *       comparator: '$after',
 *       suffix: 'last_invoked_at/total',
 *       value: 518400,
 *       parent: '397d3ca9-2d32-4e2c-f043-17ecd1107105',
 *     },
 *   ],
 * };
 * @example <caption>Generic Rule</caption>
 * const generic_rule = {
 *   id: '9623ff6f-9d83-4029-7aaf-343bd5dc5a56',
 *   group: 'device',
 *   key: 'os_version',
 *   type: 'version',
 *   value: '9.2',
 *   details: [],
 *   comparator: '$eq',
 * };
 */

import { CustomDataAttribute } from 'src/reducers/apps.types';
import { AppEventList } from 'src/reducers/events';
import { CriteriaTargetingInteractionAction } from 'src/types/core';

export type CriteriaRuleType =
  | 'parent'
  | 'version'
  | 'fan_signal'
  | 'survey_question'
  | 'survey_question_number'
  | 'survey_question_text'
  | 'survey_question_other_text'
  | 'note_action'
  | 'platform'
  | 'datetime'
  | 'duration'
  | 'string'
  | 'number'
  | 'boolean';

export enum CriteriaRuleGroup {
  APPLICATION = 'application',
  BULK_UPLOAD = 'bulk-upload',
  CODE_POINT = 'code_point',
  DEVICE = 'device',
  FS_STATE = 'fs_state',
  INTERACTIONS = 'interactions',
  IRT = 'interaction_response_targeting',
  PERSON = 'person',
  RANDOM = 'random',
}

export interface CriteriaRuleBase {
  id: string; // The rule GUID
  key: string; // The short form key
  target?: string; // The secondary target value, used by Interaction Response Targeting
  group?: CriteriaRuleGroup; // The group type for the rule
  suffix?: string; // The last portion of the key for determining rule details
  name?: string; // The human readable label for the rule
  type: CriteriaRuleType; // The type used for helping determining the final comparator for the rule
  parent?: string; // The parent rule GUID if : "a32896b3-e448-4174-45e0-a49d7dda594c"
  interactionType?: string;
}

export type CriteriaParentRule = CriteriaRuleBase & {
  details: CriteriaChildRule[]; // The collection of sub rule when the type is `parent`
};

export const isParentRule = (r: CriteriaRuleBase): r is CriteriaParentRule => 'details' in r;

export type CriteriaValue =
  | string
  | number
  | boolean
  | { _type: 'version'; version: string }
  | { _type: 'datetime' | 'duration'; sec: number };

export type CriteriaChildRule = CriteriaRuleBase & {
  value: CriteriaValue | CriteriaValue[] | undefined;
  comparator: string; // The comparator to be used for the rule
  duplicates?: number;
  survey_other?: boolean;
};

export const isChildRule = (r: CriteriaRuleBase): r is CriteriaChildRule => 'value' in r;

export type CriteriaAnyRule = CriteriaChildRule | CriteriaParentRule;

export interface CriteriaSegment {
  id: string;
  type: '$and';
  rules: (CriteriaChildRule | CriteriaParentRule)[];
}

export type CriteriaSetSegments = React.Dispatch<React.SetStateAction<CriteriaSegment[]>>;

export interface CriteriaError {
  id: string;
  message: string;
}

export type CriteriaSetErrors = React.Dispatch<React.SetStateAction<CriteriaError[]>>;

export type CriteriaAttributeType = 'device' | 'person';

export interface CriteriaAttributeValues {
  app_id?: string;
  model: string;
  name: string;
  fields: CriteriaAttributeValue[];
}

export type CriteriaAttributeValue = {
  name: string;
  key: string;
} & CustomDataAttribute;

export type CriteriaAttributes = Record<CriteriaAttributeType, CriteriaAttributeValues>;

export type CriteriaUnifiedAttributeKey = {
  app_ids: string[];
  name: string;
  key: string;
} & CustomDataAttribute;

export type CriteriaUnifiedAttributes = Record<CriteriaAttributeType, CriteriaUnifiedAttributeKey[]>;

export type CriteriaEventList = Pick<AppEventList, 'items'>;

export type CriteriaBrowserEvent = { target: { value?: CriteriaValue } };

export interface CriteriaUnifiedTargetingInteraction {
  app_ids: string[];
  unified_interaction_id: string;
  name: string;
  type: string;
}

export type CriteriaUnifiedTargetingInteractionAction = Omit<CriteriaTargetingInteractionAction, 'interaction'> & {
  unified_interaction_id: string;
};

export interface CriteriaUnifiedTargeting {
  interactions: CriteriaUnifiedTargetingInteraction[];
  interaction_responses: CriteriaUnifiedTargetingInteractionAction[];
}
