/* eslint-disable react/no-array-index-key, arrow-body-style */

import React, { ReactNode } from 'react';
import { isEmpty, path } from 'ramda';
import { bemPrefix, sortBy } from 'src/utils';
import { Loading } from 'src/components/molecules';
import { PromptMultiAppsItem } from 'src/reducers/prompts-multi-apps';
import { InteractionType } from 'src/types/core';
import { TableListPagination } from './table-list-pagination';
import { SortToggler, SortOrder } from '../../sort-toggler/sort-toggler';

import './table-list.scss';

// F => 'custom_field1' | 'custom_field2'
export interface TableHeaderProps<T = keyof Record<string, any>> {
  field: T;
  label: string;
  sortable?: boolean;
}

export type TableItemProps = Record<string, any>;

const bem = bemPrefix('app-table-list');

const defaultRenderHeader = (
  { label, sortable, field }: TableHeaderProps,
  sortByField: string | undefined,
  sortOrder: SortOrder | undefined,
  setSort: () => void,
) => (
  <th
    key={field}
    data-field={label}
    className={bem('item-header-data', {
      sortable: !!sortable,
      active: !!(sortOrder && field === sortByField),
      [field]: true,
    })}
    onClick={setSort}
  >
    {label && <span>{label}</span>}
    {sortable && <SortToggler order={field === sortByField ? sortOrder : undefined} />}
  </th>
);

const defaultRenderItemField = (item: TableItemProps, field: TableHeaderProps['field']) =>
  path(field.split('.'), item) as React.ReactNode;

const defaultRenderRow = (_item: PromptMultiAppsItem): ReactNode => null;

const defaultOnItemClick = (
  _data: { item: TableItemProps; field: TableHeaderProps['field'] },
  _e: React.SyntheticEvent,
) => {};

const defaultRenderItem = (
  item: TableItemProps,
  headers: TableHeaderProps[],
  renderItemField: typeof defaultRenderItemField = defaultRenderItemField,
  onItemClick: typeof defaultOnItemClick = defaultOnItemClick,
): JSX.Element[] | JSX.Element =>
  headers.map(({ field }, idx) => (
    <td
      key={`${field}_${item.key || idx}`}
      className={bem('item-field', field)}
      onClick={(e) => onItemClick({ item, field }, e)}
    >
      {renderItemField(item, field)}
    </td>
  ));

const defaultGetSortData = (items: TableItemProps[], field: string | undefined, sortOrder: SortOrder | undefined) => {
  return sortOrder && field ? sortBy(items, field, sortOrder) : [...items];
};

const getNextSortOrder = (sortOrder: SortOrder | undefined) => {
  const orders = [SortOrder.asc, SortOrder.desc, undefined];
  const nextSortIdx = (orders.indexOf(sortOrder) + 1) % orders.length;
  return orders[nextSortIdx];
};

export interface TableListProps {
  className?: string;
  itemsLabel?: string;
  headers: TableHeaderProps[];
  items: TableItemProps[];
  isLoading?: boolean;
  noResultsMessage?: React.ReactNode;
  noPagination?: boolean;
  itemsPerPage?: number;
  withBorder?: boolean;
  withItemContextComponent?: React.FC<{ item: TableItemProps; children: React.ReactNode }>;
  type?: InteractionType;
  renderHeader?: typeof defaultRenderHeader;
  renderItem?: typeof defaultRenderItem;
  renderItemField?: typeof defaultRenderItemField;
  renderRow?: typeof defaultRenderRow;
  getSortData?: typeof defaultGetSortData;
  onItemClick?: typeof defaultOnItemClick;
}

interface TableListState {
  sortBy: string | undefined;
  sortOrder: SortOrder | undefined;
  visibleItems: number;
  data: TableItemProps[];
  lastItems: Readonly<TableItemProps[]>;
}

const isComponent = (children: React.ReactNode) => typeof children === 'function';

const DEFAULT_VISIBLE_ITEMS = 5;

export class TableList extends React.PureComponent<TableListProps, TableListState> {
  static defaultProps = {
    className: '',
    noPagination: false,
    withBorder: false,
    itemsPerPage: DEFAULT_VISIBLE_ITEMS,
    getSortData: defaultGetSortData,
    noResultsMessage: 'No Data',
  };

  static defaultRenderHeader = defaultRenderHeader;
  static defaultRenderItem = defaultRenderItem;
  static defaultRenderItemField = defaultRenderItemField;
  static defaultGetSortData = defaultGetSortData;
  static defaultRenderRow = defaultRenderRow;

  constructor(props: TableListProps) {
    super(props);

    this.state = {
      sortBy: undefined,
      sortOrder: undefined,
      visibleItems: props.itemsPerPage || DEFAULT_VISIBLE_ITEMS,
      data: [...props.items],
      lastItems: props.items,
    };
  }

  static getDerivedStateFromProps(nextProps: TableListProps, prevState: TableListState) {
    if (nextProps.items !== prevState.lastItems) {
      const getSortData = nextProps.getSortData || TableList.defaultGetSortData;
      const data = getSortData(nextProps.items, prevState.sortBy, prevState.sortOrder);
      return {
        ...prevState,
        data,
        lastItems: nextProps.items,
      };
    }
    return null;
  }

  private getSortData: typeof defaultGetSortData = (items, field, order) => {
    const sortFunctor = this.props.getSortData || TableList.defaultGetSortData;
    return order ? sortFunctor(items, field, order) : items;
  };

  showMoreItems = (): void => this.setState({ visibleItems: this.state.visibleItems * 2 });
  showLessItems = () => this.setState({ visibleItems: this.state.visibleItems / 2 });

  setSort = (sortByField: string) => {
    const order =
      sortByField === this.state.sortBy ? getNextSortOrder(this.state.sortOrder) : getNextSortOrder(undefined);
    const data = this.getSortData(this.props.items, sortByField, order);
    this.setState({ sortBy: sortByField, sortOrder: order, data });
  };

  onSortClick = (sortByField: string) => () => {
    const field = this.props.headers.find((h) => h.field === sortByField);
    if (field && field.sortable) {
      this.setSort(sortByField);
    }
  };

  getPaginatedItems = () => {
    return this.props.noPagination ? this.state.data : this.state.data.slice(0, this.state.visibleItems);
  };

  render() {
    const {
      className,
      isLoading,
      noResultsMessage,
      noPagination,
      withBorder,
      itemsLabel,
      headers,
      withItemContextComponent,
      type,
      onItemClick = defaultOnItemClick,
      renderHeader = TableList.defaultRenderHeader,
      renderItem = TableList.defaultRenderItem,
      renderItemField = TableList.defaultRenderItemField,
      renderRow = TableList.defaultRenderRow,
    } = this.props;

    const { data } = this.state;

    const emptyList = isEmpty(this.props.items);
    const noResults = !isLoading && !data.length;
    const itemsPerPage = this.props.itemsPerPage || DEFAULT_VISIBLE_ITEMS;
    const withPagination = !noPagination && data.length > itemsPerPage;
    const mods = [withPagination ? 'with-pagination' : '', emptyList ? 'empty' : '', withBorder ? '' : 'no-border'];

    return (
      <div className={`${bem('', mods)} ${className}`}>
        {noResults ? (
          <div className={bem('no-data')}>{noResultsMessage}</div>
        ) : (
          <>
            <table className={bem('table')}>
              <thead className={bem('table-head')}>
                {!emptyList && (
                  <tr className={bem('item-header-row')}>
                    {headers.map((header) =>
                      renderHeader(header, this.state.sortBy, this.state.sortOrder, this.onSortClick(header.field)),
                    )}
                  </tr>
                )}
              </thead>
              <tbody className={bem('table-body')}>
                {this.getPaginatedItems().map((item, idx) =>
                  type === InteractionType.TextModal ? (
                    <React.Fragment key={item.key || idx}>{renderRow(item as PromptMultiAppsItem)}</React.Fragment>
                  ) : (
                    <tr className={`${bem('item-data-row')} ${className}__item`} key={item.key || idx}>
                      {isComponent(withItemContextComponent as never)
                        ? withItemContextComponent?.({
                            item,
                            children: renderItem(item, headers, renderItemField, onItemClick),
                          })
                        : renderItem(item, headers, renderItemField, onItemClick)}
                    </tr>
                  ),
                )}
              </tbody>
            </table>
            {!noPagination && (
              <TableListPagination
                itemsLabel={itemsLabel}
                visibleItemCount={this.state.visibleItems}
                itemsCount={data.length}
                defaultCount={itemsPerPage}
                onMore={this.showMoreItems}
                onLess={this.showLessItems}
              />
            )}
          </>
        )}
        {isLoading && (
          <div className={bem('loading', data.length ? 'absolute' : undefined)}>
            <Loading />
          </div>
        )}
      </div>
    );
  }
}
