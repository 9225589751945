import { useRef, useState, DependencyList, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import { isEmpty } from 'ramda';
import { isDevMode } from 'src/utils/environment';
import { InputText, SearchableSelect, SelectOption, InputCheckbox } from 'src/components/molecules';
import { RichTextEditor } from 'src/components/molecules/rich-text-editor';

type SubmitCallback = () => void;

const FORMS_ERROR_TEXT = 'Please check your form for validation errors.';
export const USE_FORM_TRIGGER_EVENT = 'USE_FORM_TRIGGER_EVENT';
/**
 * Used for single form
 * @param inputs
 * @param onSubmit
 */
export function useFormValidation(inputs: DependencyList, onSubmit?: SubmitCallback) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formIsValid, setFormIsValid] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  useWarningForCheckRefElement(formRef.current);

  const reportFormValidation = useCallback(() => {
    if (formRef.current) {
      highlightInvalidInputs(formRef.current);
    }
  }, [formRef.current, getUniqAttributesHash(formRef.current)]);

  const submitForm = useCallback(() => {
    if (!submitClicked) {
      setSubmitClicked(true);
    }
    if (!formIsValid) {
      reportFormValidation();
    } else if (onSubmit) {
      onSubmit();
    }
  }, [formIsValid, submitClicked, reportFormValidation, onSubmit]);

  useLayoutEffect(() => {
    if (formRef.current) {
      const nestedValid = checkNestedFormValidity(formRef.current);
      const refIsValid = formRef.current.checkValidity ? formRef.current.checkValidity() : true;
      setFormIsValid(refIsValid && nestedValid);
    } else {
      setFormIsValid(true);
    }
    setSubmitClicked(false);
  }, [formRef.current, getUniqAttributesHash(formRef.current), ...inputs]);

  const formError = useMemo(
    () => (!formIsValid && submitClicked ? FORMS_ERROR_TEXT : ''),
    [formIsValid, submitClicked],
  );

  return {
    formRef,
    formIsValid,
    submitClicked,
    formError,
    reportFormValidation,
    submitForm,
  };
}

/**
 * Used for case when few forms are within some element. Case with nested forms are note valid due to W3 FORM docs.
 * @param inputs
 * @param onSubmit
 */
export function useFormsValidation(inputs: DependencyList, onSubmit?: SubmitCallback) {
  const formsRef = useRef<HTMLDivElement>(null);
  const [formsAreValid, setFormsAreValid] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  useWarningForCheckRefElement(formsRef.current);

  const reportFormsValidation = useCallback(() => {
    if (formsRef.current) {
      highlightInvalidInputs(formsRef.current);
    }
  }, [formsRef.current, getUniqAttributesHash(formsRef.current)]);

  const checkFormsValidation = useCallback((forms: HTMLDivElement | null) => {
    if (forms) {
      const nestedValid = checkNestedFormValidity(forms);
      setFormsAreValid(nestedValid);
    } else {
      setFormsAreValid(true);
    }
  }, []);

  const submitForm = useCallback(() => {
    if (!submitClicked) {
      setSubmitClicked(true);
    }
    if (!formsAreValid) {
      reportFormsValidation();
    } else if (onSubmit) {
      onSubmit();
    }
  }, [formsAreValid, reportFormsValidation, onSubmit]);

  useLayoutEffect(() => {
    checkFormsValidation(formsRef.current);
    setSubmitClicked(false);
  }, [formsRef.current, getUniqAttributesHash(formsRef.current), ...inputs]);

  useLayoutEffect(() => {
    const onChangeFormListener = () => checkFormsValidation(formsRef.current);
    if (formsRef.current) {
      formsRef.current.querySelectorAll('form').forEach((f) => {
        f.addEventListener('change', onChangeFormListener);
        f.addEventListener(USE_FORM_TRIGGER_EVENT, onChangeFormListener);
      });
    }

    return () => {
      if (formsRef.current) {
        formsRef.current.querySelectorAll('form').forEach((f) => {
          f.removeEventListener('change', onChangeFormListener);
          f.removeEventListener(USE_FORM_TRIGGER_EVENT, onChangeFormListener);
        });
      }
    };
  }, [formsRef.current, getUniqAttributesHash(formsRef.current), ...inputs]);

  const formsError = useMemo(
    () => (!formsAreValid && submitClicked ? FORMS_ERROR_TEXT : ''),
    [formsAreValid, submitClicked],
  );

  return {
    formsRef,
    formsAreValid,
    formsError,
    reportFormsValidation,
    submitForm,
  };
}

/**
 * Help functions
 */

function checkNestedFormValidity(formElement: HTMLFormElement | HTMLDivElement) {
  const anyInvalidRichTextInput = Array.from(formElement.querySelectorAll(`${RichTextEditor.selectorInput}`)).some(
    (el) => el.getAttribute('data-required') === 'true' && el.getAttribute('data-empty') === 'true',
  );

  const forms = Array.from(formElement.querySelectorAll('form'));

  const hasEmptyValuesInRequiredInputsFields = forms.some((f) =>
    Array.from(f.querySelectorAll('input, textarea, select')).some((el) => {
      const value = el.tagName === 'TEXTAREA' || el.tagName === 'SELECT' ? (el as HTMLTextAreaElement | HTMLSelectElement).value : (el as HTMLInputElement).getAttribute('value');
      return el.getAttribute('required') === '' && isEmpty(value);
    },
  ));

  const anyInvalidInput = forms.map((f) => f.checkValidity() && !hasSpecialInvalidElements(f)).find((v) => !v);
  return !hasEmptyValuesInRequiredInputsFields && !anyInvalidInput && !anyInvalidRichTextInput;
}

// For highlighting invalid fields when needed
// README: For some reason, enzyme call 'simulate' does not trigger blur to elements
function highlightInvalidInputs(formElement: HTMLFormElement | HTMLDivElement) {
  const inputs = Array.from<HTMLInputElement>(formElement.querySelectorAll(`${InputText.selectorInput}:invalid`));
  const textareas = Array.from<HTMLTextAreaElement>(
    formElement.querySelectorAll(`${InputText.selectorTextarea}:invalid`)
  );

  const checkboxes = Array.from<HTMLInputElement>(
    formElement.querySelectorAll(`${InputCheckbox.selectorCheckbox}:invalid`)
  );

  const searchableSelect = Array.from<HTMLDivElement>(
    formElement.querySelectorAll(`${SearchableSelect.selector}--invalid input`)
  );

  const selects = Array.from(formElement.querySelectorAll(`${SelectOption.selectorSelect}--invalid`));

  [...inputs, ...textareas, ...checkboxes, ...searchableSelect, ...selects].forEach((el) => el.dispatchEvent(new Event('focusout', { bubbles: true }))
  );

  formElement.querySelectorAll(`${RichTextEditor.selectorInput}`).forEach(el => el.dispatchEvent(new Event(RichTextEditor.EVENT_CHECK_VALIDITY)));
}

function hasSpecialInvalidElements(form: HTMLFormElement) {
  // According to design empty <select> with preselected disabled "label", but it recognizing as valid element
  const selects = Array.from(form.querySelectorAll(`${SelectOption.selectorSelect}--invalid`));

  // Because of react-select lib, what does not pass required to input field
  const searchableSelect = Array.from<HTMLDivElement>(
    form.querySelectorAll(`${SearchableSelect.selector}--invalid input`)
  );

  return !isEmpty([...selects, ...searchableSelect]);
}

function getUniqAttributesHash(element: HTMLElement | null) {
  return element
    ? element.getAttributeNames().reduce((acc, key) => (acc += `_${key}=${element.getAttribute(key)}`), '')
    : '';
}

function useWarningForCheckRefElement(element: HTMLElement | null) {
  useEffect(() => {
    if (isDevMode() && element && isEmpty(element.getAttributeNames())) {
      console.warn('Ref HTML Element should have at least 1 attribute', element);
    }
  }, [element]);
}
