import { AppEventList } from 'src/reducers/events/events.types';
import { OrganizationApp } from 'src/reducers/organizations';
import defaultRules from '../data/rules';
import {
  CriteriaAnyRule,
  CriteriaRuleGroup,
  CriteriaChildRule,
  CriteriaParentRule,
  CriteriaUnifiedTargeting,
  CriteriaUnifiedAttributes,
} from '../types';

export const isAppCompatibleWithRule = ({
  app,
  rule,
  multipleEvents,
  unifiedTargeting,
  unifiedAttributes,
}: {
  app: OrganizationApp;
  rule: CriteriaAnyRule;
  multipleEvents: AppEventList[];
  unifiedTargeting: CriteriaUnifiedTargeting;
  unifiedAttributes: CriteriaUnifiedAttributes;
}): boolean => {
  // Check for explicit platform rules
  if (rule.group === CriteriaRuleGroup.APPLICATION && rule.key === 'platform') {
    const { comparator, value } = rule as CriteriaChildRule;
    return comparator === '$ne' ? app.platform !== value : app.platform === value;
  }

  switch (rule.group) {
    case CriteriaRuleGroup.BULK_UPLOAD: {
      const { details } = rule as CriteriaParentRule;

      if (details[0]) {
        return isAppCompatibleWithRule({ app, rule: details[0], multipleEvents, unifiedTargeting, unifiedAttributes });
      }

      return true;
    }

    case CriteriaRuleGroup.CODE_POINT: {
      const appEvents = multipleEvents.find(({ app_id }) => app_id === app.id)?.items || [];

      return appEvents.some(({ label }) => label === rule.key);
    }

    case CriteriaRuleGroup.DEVICE:
    case CriteriaRuleGroup.PERSON: {
      if (rule.key.includes('custom_data/')) {
        const groupAttributes = unifiedAttributes[rule.group];

        const attribute = groupAttributes?.find(({ key }) => key === rule.key.replace('custom_data/', 'custom_data.'));

        return attribute != null && attribute.app_ids.includes(app.id);
      }

      break;
    }

    case CriteriaRuleGroup.INTERACTIONS: {
      const app_ids =
        unifiedTargeting.interactions.find(({ unified_interaction_id: id }) => id === rule.key)?.app_ids || [];

      return app_ids.includes(app.id);
    }

    case CriteriaRuleGroup.IRT: {
      const { key, details } = rule as CriteriaParentRule;
      const app_ids = unifiedTargeting.interactions.find(({ unified_interaction_id: id }) => id === key)?.app_ids || [];

      if (!app_ids.includes(app.id)) return false;
      if (!details[0]) return false;

      return unifiedTargeting.interaction_responses.some(({ key }) => key === details[0].target);
    }

    default:
  }

  // Check for other kinds of rules
  const { group, key } = rule;
  const ruleKindValue = `${group}/${key}`;

  const ruleKind = defaultRules.find(
    ({ value }) =>
      value === ruleKindValue || value === group || (group === CriteriaRuleGroup.FS_STATE && value === key),
  );

  if (ruleKind && ruleKind.platform) {
    return ruleKind.platform.includes(app.platform);
  }

  return true;
};

export const compatibleAppsForRules = ({
  apps,
  rules,
  multipleEvents,
  unifiedTargeting,
  unifiedAttributes,
}: {
  apps: OrganizationApp[];
  rules: CriteriaAnyRule[];
  multipleEvents: AppEventList[];
  unifiedTargeting: CriteriaUnifiedTargeting;
  unifiedAttributes: CriteriaUnifiedAttributes;
}): Set<OrganizationApp> => {
  return new Set(
    apps.filter((app) =>
      rules.every((rule) =>
        isAppCompatibleWithRule({ app, rule, multipleEvents, unifiedTargeting, unifiedAttributes }),
      ),
    ),
  );
};
