/* eslint-disable no-unused-vars */
import React from 'react';
import { CriteriaAttributeType, CriteriaRuleType, CriteriaChildRule, CriteriaUnifiedAttributes } from '../types';

/**
 * Generate a 4 digit random hexadecimal string.
 *
 * @returns 4 digit hexadecimal string.
 */
const s4 = function s4(): string {
  return Math.trunc((1 + Math.random()) * 0x10000)
    .toString(16)
    .slice(1);
};

/**
 * A GUID generator.
 *
 * @returns A GUID string
 */
export const gguid = (): string => `${s4()}${s4()}-${s4()}-4${s4().slice(0, 3)}-${s4()}-${s4()}${s4()}${s4()}`;

/**
 * Converts a given key to a human friendly label.
 *
 * @param title The key to find a human friendly label for.
 * @param [group] The group the key belongs to, used for Custom Data.
 * @returns The human friendly label for the provided key or a blank string.
 */
export const cleanTitle = (title: string = '', group: string = ''): string => {
  if (title && group && title.startsWith('custom_data/')) {
    group = group.charAt(0).toUpperCase() + group.slice(1);
    title = title.replace('custom_data/', '');
    return `${group} Custom Data: ${title}`;
  }
  switch (title) {
    case 'board':
      return 'Board';
    case 'bootloader_version':
      return 'Bootloader Version';
    case 'brand':
      return 'Brand';
    case 'build':
      return 'Build';
    case 'build_number':
      return 'Build Number';
    case 'build_id':
      return 'Build ID';
    case 'build_type':
      return 'Build Type';
    case 'carrier':
      return 'Carrier';
    case 'version':
    case 'cf_bundle_version':
    case 'cf_bundle_short_version_string':
      return 'App Version';
    case 'version_code':
      return 'App Version Code';
    case 'version_name':
      return 'App Version Name';
    case 'cpu':
      return 'CPU';
    case 'current_carrier':
      return 'Current Carrier';
    case 'current_time':
      return 'Current Time';
    case 'device':
      return 'Device';
    case 'debug':
      return 'App Build Type'; // Debug
    case 'hardware':
      return 'Device Hardware';
    case 'locale_country_code':
      return 'Country';
    case 'locale_language_code':
      return 'Language';
    case 'manufacturer':
      return 'Manufacturer';
    case 'model':
      return 'Device Model';
    case 'network_type':
      return 'Network Type';
    case 'os_api_level':
      return 'OS API Level';
    case 'os_build':
      return 'OS Build';
    case 'os_name':
      return 'OS Name';
    case 'os_version':
      return 'OS Version';
    case 'platform':
      return 'Platform';
    case 'product':
      return 'Product';
    case 'radio_version':
      return 'Radio Version';
    case 'user_agent':
      return 'User Agent';
    // Fan Signals
    case 'Fan':
      return 'New Fan';
    case 'ReclaimedFan':
      return 'Shifted to Fan';
    case 'RepeatFan':
      return 'Repeat Fan';
    case 'Opportunity':
      return 'New Risk';
    case 'LostFan':
      return 'Shifted to Risk';
    case 'RepeatOpportunity':
      return 'Repeat Risk';
    default:
      return title && title != null ? title : '';
  }
};

/**
 * Converts a given key to a valid comparator type.
 *
 * @param value The key to find a comparator type for.
 * @param [unifiedAttributes] The collection of possible Person / Device attributes used for custom data
 * @returns The comparator type for the provided key or 'string' as a fallback.
 */
export const getAttributeType = (
  value: string,
  unifiedAttributes?: Readonly<CriteriaUnifiedAttributes>,
): CriteriaRuleType => {
  if (value && value.includes('random/')) {
    return 'number';
  }
  if (value && value.includes('custom_data/') && unifiedAttributes) {
    const [rawGroup, , key] = value.split('/');
    if (['device', 'person'].includes(rawGroup)) {
      const group = rawGroup as CriteriaAttributeType;
      if (unifiedAttributes[group]) {
        const found = unifiedAttributes[group].find((field) => field.key === `custom_data.${key}`);
        if (found) {
          return found.type as CriteriaRuleType;
        }
      }
    }
  }
  switch (value) {
    // Version
    case 'application/cf_bundle_short_version_string':
    case 'application/cf_bundle_version':
    case 'application/version_name':
    case 'application/version': // Old, Replaced by `cf_bundle_short_version_string`, `version_code`, `version_name`
    case 'sdk/version':
    case 'device/os_version': // iOS String / Android Version
      return 'version';

    // Boolean
    case 'application/debug':
    case 'is_update/build_number':
    case 'is_update/build':
    case 'is_update/cf_bundle_short_version_string': // Legacy
    case 'is_update/cf_bundle_version':
    case 'is_update/version_code':
    case 'is_update/version_name':
    case 'is_update/version': // Legacy, Replaced by `is_update/version_code`, `is_update/cf_bundle_version`
      return 'boolean';

    // Platform
    case 'application/platform':
      return 'platform';

    // DateTime / Timestamp
    case '/current_time':
    case '/local_time':
    case 'local_time':
    case 'current_time':
      return 'datetime';

    // DateTime / Timestamp (Previously Duration)
    case 'code_point/invokes/time_ago': // Old, Replaced by `code_point/last_invoked_at/total`
    case 'code_point/last_invoked_at/total':
    case 'interactions/invokes/time_ago': // Old, Replaced by `interactions/last_invoked_at/total`
    case 'interactions/last_invoked_at/total':
    case 'time_at_install/build_number':
    case 'time_at_install/build':
    case 'time_at_install/cf_bundle_short_version_string':
    case 'time_at_install/cf_bundle_version':
    case 'time_at_install/total':
    case 'time_at_install/version_code':
    case 'time_at_install/version': // Old, Replased by `time_at_install/version_code`, `time_at_install/cf_bundle_version`
    case 'time_since_install/total': // Old, Replaced by `time_at_install/total`
    case 'time_since_install/version': // Old, Replaced by `time_at_install/version`
      return 'duration';

    // Number
    case 'device/os_api_level':
    case 'device/utc_offset': // Old, Removed
    case '/utc_offset': // Old, Removed
    case 'utc_offset': // Old, Removed
    case 'application/build_number':
    case 'application/build':
    case 'application/version_code':
    case 'code_point/invokes/build_number':
    case 'code_point/invokes/build':
    case 'code_point/invokes/cf_bundle_short_version_string':
    case 'code_point/invokes/cf_bundle_version':
    case 'code_point/invokes/version_code':
    case 'code_point/invokes/version_name':
    case 'code_point/invokes/version': // Used by Web & Legacy SDKs. Replaced by `code_point/invokes/version_code` for Android, `code_point/invokes/cf_bundle_version` for iOS
    case 'code_point/invokes/total':
    case 'interactions/invokes/build_number':
    case 'interactions/invokes/build':
    case 'interactions/invokes/cf_bundle_short_version_string':
    case 'interactions/invokes/cf_bundle_version':
    case 'interactions/invokes/version_code':
    case 'interactions/invokes/version_name':
    case 'interactions/invokes/version': // Used by Web & Legacy SDKs. Replaced by `interactions/invokes/version_code` for Android, `interactions/invokes/cf_bundle_version` for iOS
    case 'interactions/invokes/total':
      return 'number';

    // String
    case 'device/board':
    case 'device/bootloader_version':
    case 'device/brand':
    case 'device/build_id':
    case 'device/build_type':
    case 'device/carrier':
    case 'device/cpu':
    case 'device/current_carrier':
    case 'device/device':
    case 'device/hardware': // Legacy, then brought back for iOS, Android uses `device/model`
    case 'device/locale_country_code':
    case 'device/locale_language_code':
    case 'device/locale_raw': // Old, Removed
    case 'device/manufacturer':
    case 'device/model': // iOS no longer returns this field
    case 'device/network_type':
    case 'device/os_build':
    case 'device/os_name':
    case 'device/product':
    case 'device/radio_version':
    case 'device/uuid': // Old, Removed
    case 'device/user_agent':
    case 'person/email':
    case 'person/name':
    case 'interactions/answers/id':
    case 'interactions/answers/value':
    case 'interaction_response_targeting/answers/id':
    case 'interaction_response_targeting/answers/value':
      return 'string';

    // Fan Signals
    case 'fs_state/Fan':
    case 'fs_state/LostFan':
    case 'fs_state/Opportunity':
    case 'fs_state/ReclaimedFan':
    case 'fs_state/RepeatFan':
    case 'fs_state/RepeatOpportunity':
      return 'fan_signal';

    // Interaction Response Targeting - Survey Questions
    case 'range':
    case 'nps':
      return 'survey_question_number';
    case 'singleline':
    case 'multiline':
    case 'multiselect_other':
    case 'multichoice_other':
      return 'survey_question_text';
    case 'multiselect':
    case 'multichoice':
    case 'Survey':
      return 'survey_question';

    // Interaction Response Targeting - Note / TextModal Actions
    case 'TextModal':
    case 'dismiss':
    case 'interaction':
      return 'note_action';

    // Unknown
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown Attribute Type:', value);
      return 'string';
  }
};

/**
 * Returns a sane default value for a given type.
 *
 * @param type The type to find the default for.
 * @returns The default value for the provided type.
 */
export const getDefaultValueForType = (type: CriteriaRuleType): CriteriaChildRule['value'] => {
  switch (type) {
    // Version
    case 'version':
      return '1.0.0';

    // Boolean
    case 'boolean':
      return true;

    // Platform
    case 'platform':
      return 'Web';

    // DateTime / Timestamp
    case 'datetime':
      return Date.now();

    // DateTime / Timestamp (Previously Duration)
    case 'duration':
      return 0;

    // Number
    case 'number':
      return 0;

    // String
    case 'string':
      return '';

    case 'fan_signal':
      return undefined;

    // Unknown
    default:
      // eslint-disable-next-line no-console
      console.error('Unknown type to get default type for:', type);
      return undefined;
  }
};

/**
 * Returns a sane default set of details for a given type.
 *
 * @param type The type to find the default for.
 * @param parent The ID of the containing rule.
 * @returns The default details for the provided type.
 */
export const getDefaultDetailsForType = ({
  type,
  parent,
  key,
}: {
  type: string;
  parent: string;
  key: string;
}): CriteriaChildRule[] => {
  switch (type) {
    case 'code_point':
      return [
        {
          parent,
          id: gguid(),
          type: 'number',
          comparator: '$eq',
          suffix: 'invokes/total',
          value: 1,
          key,
        },
      ];

    case 'fs_state':
      return [
        {
          parent,
          id: gguid(),
          type: 'fan_signal',
          comparator: '$eq',
          value: true,
          key,
        },
      ];

    case 'interactions':
      return [
        {
          parent,
          id: gguid(),
          type: 'number',
          comparator: '$eq',
          suffix: 'invokes/total',
          value: 1,
          key,
        },
      ];

    case 'bulk-upload':
      return [
        {
          parent,
          id: gguid(),
          comparator: '$eq',
          value: [] as string[],
          key,
          group: undefined,
          duplicates: 0,
          type: 'string',
        },
      ];

    // Unknown
    default:
      return [];
  }
};

/**
 * Check keyed input for semver values.
 *
 * @param e Event
 */
export const versionOnly = (e: React.KeyboardEvent) => {
  const code = e.which || e.keyCode || 0;
  // Allow: Backspace, Delete, Tab, Escape, Enter, Return
  if ([46, 8, 9, 27, 13, 110].includes(code)) {
    return;
  }
  // Allow: Decimals
  if ([190].includes(code)) {
    return;
  }
  // Allow: Ctrl or Command + [A,C,V,X,Z]
  if ((e.ctrlKey || e.metaKey) === true && [65, 67, 86, 88, 90].includes(code)) {
    return;
  }
  // Allow: Up, Down, Home, End, Left, Right
  if (code >= 35 && code <= 40) {
    return;
  }
  // Ensure that it is a number 0-9, A-F, and stop the keypress.
  if ((e.shiftKey || code < 48 || code > 57) && (code < 96 || code > 105)) {
    e.preventDefault();
  }
};

/**
 * Check keyed input for integer values.
 *
 * @param e Event
 */
export const integerOnly = (e: React.KeyboardEvent) => {
  const code = e.which || e.keyCode || 0;
  // Allow: Backspace, Delete, Tab, Escape, Enter, Return
  if ([46, 8, 9, 27, 13, 110].includes(code)) {
    return;
  }
  // Allow: Ctrl or Command + [A,C,V,X,Z]
  if ((e.ctrlKey || e.metaKey) === true && [65, 67, 86, 88, 90].includes(code)) {
    return;
  }
  // Allow: Up, Down, Home, End, Left, Right
  if (code >= 35 && code <= 40) {
    return;
  }
  // Ensure that it is a number 0-9, A-F, and stop the keypress.
  if ((e.shiftKey || code < 48 || code > 57) && (code < 96 || code > 105)) {
    e.preventDefault();
  }
};

/**
 * Check keyed input for numeric values.
 *
 * @param e Event
 */
export const numericOnly = (e: React.KeyboardEvent) => {
  const code = e.which || e.keyCode || 0;
  // Allow: Backspace, Delete, Tab, Escape, Enter, Return
  if ([46, 8, 9, 27, 13, 110].includes(code)) {
    return;
  }
  // Allow: Only one Decimal
  if ([190].includes(code)) {
    const currentTarget = e.currentTarget as { value?: string };

    if (!(currentTarget && currentTarget.value && /\./g.test(currentTarget.value))) {
      return;
    }
  }
  // Allow: Ctrl or Command + [A,C,V,X,Z]
  if ((e.ctrlKey || e.metaKey) === true && [65, 67, 86, 88, 90].includes(code)) {
    return;
  }
  // Allow: Up, Down, Home, End, Left, Right
  if (code >= 35 && code <= 40) {
    return;
  }
  // Ensure that it is a number 0-9, A-F, and stop the keypress.
  if ((e.shiftKey || code < 48 || code > 57) && (code < 96 || code > 105)) {
    e.preventDefault();
  }
};

/**
 * Convert seconds to days, hours, minutes or seconds
 *
 * @param [seconds=0] The nunber of seconds to convert to days, hours, minutes or seconds.
 * @returns string]} Returns an array of the parseed value and the time period it correlates to.
 */
export const parseDuration = (seconds: number = 0): [number, string] => {
  const isSec = Math.floor(seconds % 60) > 0;
  const isMins = Math.floor((seconds / 60) % 60) > 0;
  const isHours = Math.floor((seconds / 3600) % 24) > 0;

  let input;
  let drop;
  if (isSec || !seconds) {
    drop = 'seconds';
    input = seconds;
  } else if (isMins) {
    drop = 'minutes';
    input = seconds / 60;
  } else if (isHours) {
    drop = 'hours';
    input = seconds / 3600;
  } else {
    drop = 'days';
    input = Math.floor(seconds / 86400);
  }

  return [input, drop];
};

/**
 * A simpler clone of classnames from https://github.com/JedWatson/classnames
 *
 * @param classes The classes to conditionally add.
 * @returns A list of classes to add to a DOM element.
 */
export const classNames = (classes: Record<string, any> = {}): string => {
  if (!classes || typeof classes !== 'object') {
    return '';
  }
  const output: string[] = [];
  for (const [key, value] of Object.entries(classes)) {
    if (value && !output.includes(key)) {
      output.push(key);
    }
  }
  return output.join(' ');
};

/**
 * Convert an UTC date to a Local Date, from https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-770313939
 *
 * @param date The UTC date.
 * @returns The Local date.
 */
export const convertUTCToLocalDate = (date: Date): Date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
};

/**
 * Convert a Local date to an UTC Date, from https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-770313939
 *
 * @param date The Local date.
 * @returns The UTC date.
 */
export const convertLocalToUTCDate = (date: Date): Date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return date;
};

export default {
  classNames,
  cleanTitle,
  convertLocalToUTCDate,
  convertUTCToLocalDate,
  getAttributeType,
  gguid,
  integerOnly,
  numericOnly,
  parseDuration,
  versionOnly,
};
