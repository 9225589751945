import { EventItem } from 'src/reducers/events';

type AppId = string;

export interface AppsState extends Record<AppId, AppState> {} // eslint-disable-line

export enum AppMemberRole {
  Admin = 'admin',
  Collaborator = 'collaborator',
  Reporter = 'reporter',
}

// Pure data with props from mongo by schema-interaction-service
export interface AppData {
  id: string;
  organization_id: string;
  title: string;
  selected_cid_keys: SelectedCidKey[] | null;
  love_score: LoveScore;
  slReach?: SLReachResp;
  rcReach?: ReachResp;
  richTextReach?: ReachResp;
  love_ratio?: string;
  mau?: string;
  current_rating?: number;
  all_ratings?: number;
  interactions?: string;
  initiatorReach?: ReachResp;
}

export interface SLReachResp {
  app_id: string;
  start_date: string;
  end_date: string;
  total_mau: number;
  skip_logic_percentage: number;
}

export interface ReachResp {
  app_id: string;
  start_date: string;
  end_date: string;
  total_mau: number;
  reach_percentage: number;
}

export interface LoveScore {
  score: number;
  categoryScore: number;
  categoryName: string;
}

export interface SelectedCidKey {
  id?: string;
  name: string;
  primary: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface AppMember {
  id: string;
  role: AppMemberRole;
  name: string;
  email: string;
  user_id: string;
}

export interface AttributeField {
  type: 'boolean' | 'string' | 'number';
  name: string;
  key: string;
}

export interface AttributeChoice {
  app_id: string;
  fields: AttributeField[];
  model: 'device' | 'person';
  name: string; // Device, Person
}

export interface AppState {
  // Related with FETCH_ALL_EVENTS_SUCCESS
  allEvents: { bootstrapped: boolean; loading: boolean; items: EventItem[] };
  appMembers: AppMember[];
  attributeChoices: Record<'device' | 'person', AttributeChoice>;
  attributeChoicesBootstrapped: Record<'device' | 'person', boolean>;
  styles: { name: string; value: string }[];
  // not clear props
  events: any;
  unseenEvents?: any[];
  archivedEvents?: any[];
  addAppMemberFailureErrorMessage?: any;
  translations?: any;
  translationsLoading?: boolean;
  translationsError?: any;
  translationsErrorMessage?: any;
  translationsSaving?: boolean;
  translationsSavingError?: any;
  translationsSavingErrorMessage?: any;
  translationsStatus?: any;
  integrations?: any;
  integrationsLoading?: boolean;
  integrationsSaving?: boolean;
  integrationsSavingError?: any;
  integrationsSavingErrorMessage?: any;
  attributeChoicesError?: any;
  attributeChoicesLoading?: boolean;
  quickResponses?: any;
  appStoreIcon?: string;
  appStoreIconLoading?: boolean;
  appStoreIconError?: boolean;
  authenticationKey?: string;
  authenticationSignature?: string;
  oauthToken?: string;
  jwtSecret?: string;
  debugJwtToken?: string;
  deleteAppPending?: boolean;
  deleteAppFailure?: boolean;
  deleteAppFailureMessage?: any;
  deleteAppSuccess?: boolean;
  updateAppPending?: boolean;
  updateAppFailure?: boolean;
  updateAppFailureMessage?: any;
  updateAppSuccess?: boolean;
}

export interface IBaseWebhook {
  app_id: string;
  org_id: string;
  is_active: boolean;
  webhook_url: string;
  fs_state: string[];
}

export interface IWebhook extends IBaseWebhook {
  id: string;
}

export interface ITestWebhook {
  webhook_url: string;
}

export interface AttachmentPayload {
  user_id: string;
  app_id: string;
  conversation_id: string;
  message_id: string;
  attachment_id: string;
}

export interface AppAttributes {
  keys: Array<string>;
  suggested_keys: Array<string>;
}

export interface CustomDataAttribute {
  type?: string;
  is_visible: boolean;
  description: string;
}

export type CustomDataEntity = 'device' | 'person';
