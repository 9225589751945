import {
  CriteriaUnifiedAttributes,
  CriteriaUnifiedTargeting,
} from 'src/interactions/newinteractions/targeting/multi-app-segment-builder/types';
import { filterIncompatibleCriteria } from 'src/interactions/newinteractions/targeting/multi-app-segment-builder/util/filter-incompatible';
import { AppInteractionData, TargetingCriteria } from 'src/types/core';
import { AppEventList } from '../events';
import { OrganizationApp } from '../organizations';

export enum ImtPermisson {
  CREATOR = 'CREATOR',
  HAS_ACCESS_TO_ALL_APPS = 'HAS_ACCESS_TO_ALL_APPS',
  HAS_ACCESS_TO_SOME_APPS = 'HAS_ACCESS_TO_SOME_APPS',
}

export class ImtModel {
  static getUserPermissions(
    model: { createdBy: string; interactionData: AppInteractionData[] },
    currentUserId: string,
    availableApps: string[],
  ): ImtPermisson {
    if (model.createdBy === currentUserId) {
      return ImtPermisson.CREATOR;
    }

    const modelAppIdsSet = new Set(availableApps);
    return model.interactionData.some((interaction) => !modelAppIdsSet.has(interaction.appId))
      ? ImtPermisson.HAS_ACCESS_TO_SOME_APPS
      : ImtPermisson.HAS_ACCESS_TO_ALL_APPS;
  }

  static getAvailableInteractionData(
    model: { interactionData: AppInteractionData[] },
    availableApps: OrganizationApp[],
  ): AppInteractionData[] {
    return model.interactionData.filter(({ appId }) => availableApps.some(({ id }) => id === appId));
  }

  static getPerAppCriteria({
    criteria,
    model: { interactionData },
    organizationApps,
    multipleEvents,
    unifiedTargeting,
    unifiedAttributes,
  }: {
    criteria: TargetingCriteria;
    model: { interactionData: AppInteractionData[] };
    organizationApps: OrganizationApp[];
    multipleEvents: AppEventList[];
    unifiedTargeting: CriteriaUnifiedTargeting;
    unifiedAttributes: CriteriaUnifiedAttributes;
  }): Record<string, TargetingCriteria> {
    const appIds = interactionData.map(({ appId }) => appId);
    const appsInInteraction = appIds
      .map((appId) => organizationApps.find(({ id }) => appId === id))
      .filter((app) => !!app) as OrganizationApp[];

    return Object.fromEntries(
      appsInInteraction.map((app) => [
        app.id,
        filterIncompatibleCriteria({ app, criteria, multipleEvents, unifiedAttributes, unifiedTargeting }),
      ]),
    );
  }
}
