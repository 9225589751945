import { AppCustomData } from 'src/actions/app-data';
import { AppData, AppState, AppAttributes, CustomDataAttribute } from 'src/reducers/apps.types';
import { EventItem } from 'src/reducers/events';

export enum UserFeature {
  ToastNotifications = 'toast_notifications',
  FanSignalsWebhooks = 'fan_signals_webhooks',
  CsvExports = 'csv_exports',
  MultipleLoveDialogs = 'multiple_love_dialogs',
  ConversationId = 'conversation_id',
  UnlimitedFanSignalsExport = 'unlimited_fan_signals_export',
  RightNowIntegration = 'rightnow_integration',
  Quantum_metric_link = 'quantum_metric_link',
  SummaryGraphLabels = 'summary_graph_labels',
  RichText = 'rich_text',
  GridNav = 'grid_nav',
  Survey = 'survey',
  Workflow = 'workflow',
  Pulse = 'pulse',
  AdvCustomerResearch = 'adv_customer_research',
  InteractionsMultitarget = 'interactions_multitarget',
  WorkflowInitiator = 'workflow_initiator',
  ContextualPageTitles = 'contextual_page_titles',
}

export type Features =
  | UserFeature
  | 'allow_hide_branding'
  | 'apptimize_integration'
  | 'conversations_export'
  | 'custom_banner'
  | 'desk_hook_active'
  | 'developer_console'
  | 'disable_message_center'
  | 'ekg_reviews'
  | 'enhanced_dashboard'
  | 'enhanched_dashboard'
  | 'github_integration'
  | 'groups'
  | 'integrations'
  | 'message_insights'
  | 'notifications'
  | 'note_notification_drawer'
  | 'phrase_explorer'
  | 'power_user'
  | 'rating_prompt_decoupling'
  | 'sentiment'
  | 'slack_integration'
  | 'survey_reports'
  | 'translation'
  | 'user_study_list'
  | 'web_sdk'
  | 'create_new_app_org'
  | 'salesforce_hook_active';

export interface IUser {
  id: string;
  name: string;
  email: string;
  role?: string;
  companyTelephone?: string;
  professionalRole?: string;
  timeZone?: string;
  features: Features[] | UserFeature[];
  surveyReportSubIds?: string[];
  uiState?: Record<string, any>;
}

export type ApplicationIntegratedFeature = 'survey' | 'text_modal' | 'message_center' | 'rating_flow';

export enum Platform {
  Web = 'Web',
  Android = 'Android',
  iOS = 'iOS',
}

export type BasicFn = () => void;

export type ApplicationLocalePolicy = 'fallback' | 'strict';

export enum SdkVersion {
  SupportsSkipLogic = 'v6.1',
  SupportsTermsAndConditions = 'v6.1',
  SupportsRichContent = 'v6.6',
  SupportsRichText = 'v6.7',
  SupportsInitiators = 'v6.9',
}

export const ALL_SDK_VERSIONS = [
  SdkVersion.SupportsSkipLogic,
  // SupportsTermsAndConditions omitted as it's a duplicate
  SdkVersion.SupportsRichContent,
  SdkVersion.SupportsRichText,
  SdkVersion.SupportsInitiators,
];

export type ReachByVersion = Partial<Record<SdkVersion, number>>;
export type ReachByAppAndVersion = Record<string, ReachByVersion>;

export type FetchAppFn = (app_id: string) => Promise<IApplication>;
export interface IApplication extends AppState {
  targeting: CriteriaTargeting;
  targetingError: boolean;
  targetingLoading: boolean;
  id: string;
  defaultLocale?: string;
  displayName?: string;
  hideBranding: boolean;
  iconUrl?: string;
  integratedFeatures?: Record<ApplicationIntegratedFeature, boolean>;
  interactionReminderEmails?: null;
  intlAppStorePref: boolean;
  lastUpdated: number;
  live: boolean;
  localePolicy?: ApplicationLocalePolicy;
  loading?: boolean;
  organization: string;
  platform: Platform;
  storeAppId?: string;
  messageCenterId?: string;
  supportDisplayEmail?: string;
  supportDisplayName?: string;
  supportImageUrl?: string;
  title: string;
  wentLiveOn?: number;
  websdkDebugFlag?: boolean;
  client_support?: Record<string, string>;
  selected_cid_keys?: AppData['selected_cid_keys'];
  love_score?: AppData['love_score'];
  appAttributes?: AppAttributes;
  store_id?: string;
  reachBySdkVersion?: ReachByVersion;
  isCustomDataPending: boolean;
  isCustomDataFailure: boolean;
  personCustomDataLoadingKeys: string[];
  deviceCustomDataLoadingKeys: string[];
  personActiveCustomData: Record<string, CustomDataAttribute>;
  personArchievedCustomData: Record<string, CustomDataAttribute>;
  deviceActiveCustomData: Record<string, CustomDataAttribute>;
  deviceArchievedCustomData: Record<string, CustomDataAttribute>;
  appCustomData?: AppCustomData;
  isAppCustomDataLoading?: boolean;
  love_ratio?: string;
  mau?: string;
  current_rating?: number;
  all_ratings?: number;
  interactions?: string;
  latestSDKVersion?: string;
  current_mau?: number;
}

// TODO: What other possible survey states are there?
// export type SurveyState = 'running';

export enum SurveyQuestionType {
  Multiselect = 'multiselect',
  Multichoice = 'multichoice',
  Singleline = 'singleline',
  Range = 'range',
  Nps = 'nps',
}

export interface QuestionAnswerChoice {
  id?: string;
  branching_id?: string;
  branchingId?: string;
  logical_id?: string;
  logicalId?: string;
  value: string;
  type: 'select_option' | 'select_other';
  _destroy?: 1; // README: The flag for remove choice from DB by WEB
}

export interface ISurveyQuestion {
  id?: string;
  type: SurveyQuestionType;
  required: boolean;
  value: string;
  error_message?: string;
  errorMessage?: string;
  randomize?: boolean;
  multiline?: boolean;
  min_selections?: number;
  minSelections?: number;
  max_selections?: number;
  maxSelections?: number;
  other_choice?: boolean;
  otherChoice?: boolean;
  other_choice_text?: string;
  otherChoiceText?: string;
  min?: number;
  max?: number;
  min_label?: string;
  minLabel?: string;
  max_label?: string;
  maxLabel?: string;
  answer_choices?: QuestionAnswerChoice[];
  answerChoices?: QuestionAnswerChoice[];
  freeform_hint?: string;
  freeformHint?: string;
  instructions?: string;
  _destroy?: 1; // README: The flag for remove question from DB by WEB
}

export type CriteriaKey = keyof typeof CriteriaOperator;
export type CriteriaRule = Record<CriteriaKey, string | number | boolean>;
export type SkipLogicCriteriaOption = Record<string, CriteriaRule>;

export interface ActionAttribute {
  id?: string;
  order?: number;
  behavior: 'continue' | 'end';
  next_logical_id?: string;
  nextLogicalId?: string;
  criteria?: Record<string, CriteriaRule>;
  _destroy?: 1; // README: The flag for invoke from DB by WEB
}

export type ISurveyLogicQuestion = ISurveyQuestion & { logical_id?: string; logicalId?: string };

export interface ISurveyQuestionSet {
  id?: string;
  logical_id?: string;
  logicalId?: string;
  order: number;
  next_button_text?: string;
  nextButtonText?: string;
  submit_button_text?: string;
  submitButtonText?: string;
  invokes?: ActionAttribute[];
  questions: ISurveyLogicQuestion[];
  _destroy?: 1; // README: The flag for remove question from DB by WEB
}

export interface IMultiChoiceQuestion extends ISurveyQuestion {
  instructions: string;
  otherChoiceText?: string;
  answerChoices?: ISurveyAnswerChoice[];
  minSelections: number;
  maxSelections: number;
}

export interface IRangeQuestion extends Omit<ISurveyQuestion, 'maxLabel' | 'minLabel'> {
  min?: number;
  max?: number;
  maxLabel?: number;
  minLabel?: number;
}

export interface IFreeFormQuestion extends ISurveyQuestion {
  multiline: boolean;
  freeformHint?: string;
}

export type SurveyAnswerChoiceType = 'select_option' | 'select_other';

export interface ISurveyAnswerChoice {
  id: string;
  value: string;
  type: SurveyAnswerChoiceType;
}

export type FanSignalResponseStatus =
  | 'fans'
  | 'opportunities'
  | 'reclaimedFans'
  | 'lostFans'
  | 'repeatFans'
  | 'repeatOpportunities';

export interface InteractionTrigger {
  id: string;
  type: InteractionType;
  name?: string;
  title?: string;
}

export interface IReachDataRatios {
  app_ratio: number;
  avg_ratio: number;
  max_ratio: number;
}

export interface IReachData {
  conversations?: IReachDataRatios;
  love_dialog?: IReachDataRatios;
  surveys?: IReachDataRatios;
  notes?: IReachDataRatios;
}

export interface IReferenceLine {
  date: Date | string | number | null;
  label: string;
  color?: string;
}

export type DateAnnotationType = 'appRelease' | 'custom' | undefined;

export interface IDateAnnotation {
  id: string;
  label: string;
  startDate: Date;
  endDate: Date;
  type?: DateAnnotationType;
  color: string | undefined;
  comment: string | undefined;
  createdBy: string;
  createdAt: string;
}

// These values should match SUPPORTED_METRICS in app/models/app/metric_threshold.rb
export enum MetricThresholdMetric {
  AppStoreRating = 'app_store_rating',
  LovePercent = 'love_percent',
}

export interface MetricThreshold {
  id: string;
  metric: MetricThresholdMetric;
  lowerThreshold: number;
  isActive: boolean;
}

export interface IAppStoreVersion {
  version: string;
  date: number;
}

export interface ITimeSeriesDatum {
  date: number;
  y: number;
}

export interface HistogramDataPoint {
  x: Date;
  y: number;
  meta: {
    originalDatum: ITimeSeriesDatum;
    originalTotal?: number;
    positionInSeries: number;
    label: string;
  };
}

export interface HistogramTimeSeries {
  label: string;
  data: HistogramDataPoint[];
  color: string;
}

export enum EmailFrequency {
  Unsubscribed = 'unsubscribed',
  Daily = 'daily',
  Weekly = 'weekly',
}

/**
 * Download object for Download Center.
 *
 * @typedef DownloadCenterDownload
 * @type {object}
 * @property {string} app_id The App BSON ID for the download.
 * @property {string} app_name The App name for the download.
 * @property {string} platform The App platform (iOS< Android, Web, etc.) for the download.
 * @property {string} user_id The User BSON ID to create the download for.
 * @property {Date} date_requested
 * @property {string} filename The file name for the download to be stored as.
 * @property {number} filesize The file size for the download, should be 0 until populated.
 * @property {string} parameters The JSON stringified parameters needed to request the download upstream.
 * @property {string} status The status of the download center job.
 * @property {DownloadType} type The type of the download center job. One of: AppHealth, AppRollup, Conversations, Survey, Translations
 */

export interface DownloadCenterDownload {
  app_id: string;
  app_name: string;
  platform: string;
  user_id: string;
  date_requested: Date;
  filename: string;
  filesize: number;
  parameters: string;
  status: string;
  type: DownloadType;
}

export enum DownloadType {
  AppHealth = 'AppHealth',
  AppRollup = 'AppRollup',
  Conversations = 'Conversations',
  Survey = 'Survey',
  Translations = 'Translations',
}

// Targeting

// These type will liekly grow and change as Interactions are added or removed.
// See: https://github.com/apptentive/web/blob/master/app/models/app.rb#L723
export interface CriteriaTargetingInteraction {
  id: string;
  name: string;
  type: string;
}

export interface CriteriaTargetingInteractions {
  name: string;
  group: string;
  options: CriteriaTargetingInteraction[];
}

export interface CriteriaTargetingInteractionAnswerChoice {
  id: string;
  value: string;
  type: string;
}

export interface CriteriaTargetingInteractionAction {
  key: string;
  name: string;
  type: string;
  interaction: string;
  interactionType?: string;
  url?: string;
  target?: string;
  answer_choices?: CriteriaTargetingInteractionAnswerChoice[];
  min?: number;
  max?: number;
  append_variables?: string[];
}

export interface CriteriaTargetingInteractionActions {
  name: string;
  group: string;
  options: CriteriaTargetingInteractionAction[];
}

export interface CriteriaTargeting {
  interactions: CriteriaTargetingInteractions;
  interaction_response_targeting: CriteriaTargetingInteractionActions;
}

export interface EventMetricsPayload {
  app_id: string;
  user_id: string;
  event_name: string;
  created_at: string;
}

// Interactions
export enum InteractionType {
  Survey = 'Survey',
  TextModal = 'TextModal', // Prompt
  EnjoymentDialog = 'EnjoymentDialog', // Love Dialog
  MessageCenterV2 = 'MessageCenterV2', // Message Center = Open Message Center - SDK 2.0
  RatingDialog = 'RatingDialog',
  AppleRatingDialog = 'AppleRatingDialog',
  GoogleRatingDialog = 'InAppRatingDialog', // historical https://github.com/apptentive/apptentive-android-private/pull/652
  Initiator = 'Initiator',
}

/**
 * Types from legacy
 * | 'MessageCenterV2'
 * | 'EnjoymentDialog'
 * | 'RatingDialog'
 * | 'AppleRatingDialog'
 * | 'AppStoreRating'
 * | 'RatingPrompt'
 * | 'ThankYouNote'
 * | 'RatingFlowV2'
 * | 'Survey'
 * | 'TextModal';
 */
export const InteractionTypeLabel: Record<InteractionType, string> = {
  [InteractionType.Survey]: 'Survey',
  [InteractionType.TextModal]: 'Note',
  [InteractionType.EnjoymentDialog]: 'Love Dialog',
  [InteractionType.MessageCenterV2]: 'Message Center',
  [InteractionType.RatingDialog]: 'Rating Dialog',
  [InteractionType.AppleRatingDialog]: 'Apple Rating Dialog',
  [InteractionType.GoogleRatingDialog]: 'Google Rating Dialog',
  [InteractionType.Initiator]: 'Workflow Initiator',
};

export const getInteractionTypeLabel = (type: InteractionType) => InteractionTypeLabel[type];

export interface InteractionItem {
  active: boolean;
  code_points?: EventItem['label'][] | EventItem['label'];
  criteria?: TargetingCriteria;
  start_time?: string | null;
  end_time?: string | null;
  created_at?: string;
}

export interface SegmentAndRules {
  $and: Record<string, any>[];
}

/**
 * CriteriaOperator.Or - segmentation - Who
 */
export interface TargetingCriteria {
  $and?: SegmentAndRules['$and'];
  $or?: SegmentAndRules[];
  _version?: number;
}

export const NEVER_MATCHING_CRITERIA = { $or: [] };

export const isNeverMatchingCriteria = (criteria: TargetingCriteria): boolean =>
  !!criteria.$or && !criteria.$and && criteria.$or.length === 0;

export enum CriteriaOperator {
  $and = '$and',
  $or = '$or',
  $eq = '$eq',
  $ne = '$ne',
  $startsWith = '$starts_with',
  $endsWith = '$ends_with',
  $contains = '$contains',
  $lt = '$lt',
  $gt = '$gt',
  $lte = '$lte',
  $gte = '$gte',
  $before = '$before',
  $after = '$after',
  $exists = '$exists',
}

export interface EventDistributionData {
  label: string;
  count?: number;
  datums: ITimeSeriesDatum[];
}

export interface BasicIntegration {
  active: boolean;
  type: string;

  // optional fields for every integration differs
  access_key_id?: string | null;
  secret_access_key?: string | null;
  app_arn?: string | null;
  sandbox_app_arn?: string | null;
  min_period?: number | null;
  delay?: number | null;
  sound_enabled?: boolean | null;
  sound_value?: string;
  monitor_hook?: boolean | null;
  hook_notice_recipients?: string | null;
  gcm_api_key?: string | null; // write-only
  apn_bundle?: string | null; // write-only
  sandbox_apn_bundle?: string | null; // write-only
  apn_pass?: string | null; // write-only
  sandbox_apn_pass?: string | null; // write-only
  provider?: string | null; // read-only, "GCM" or "APNS"
  application_key?: string | null;
  application_secret?: string | null;
  master_secret?: string | null;
  badge_enabled?: boolean | null;
  badge_value?: string;
  webhook_url?: string | null;
  channel?: string | null;
  username?: string | null;
  consumer_key?: string | null;
  consumer_secret?: string | null;
  subdomain?: string | null;
  oauth_token?: string | null;
  oauth_token_secret?: string | null;
  ticket_subject?: string;
  end_of_rule_name?: string;
  custom_fields?: Record<string, string> | null;
  labels?: string[] | null;
  api_key?: string | null;
  api_secret?: string | null;
  sso_key?: string | null;
  auth_token?: string | null;
  fallback_username?: string;
  add_conv_link?: boolean | null;
  application_id?: string | null;
  rest_api_key?: string | null;
  instance_url?: string | null;
  password?: string | null;
  id?: string | undefined;
  fs_state?: string[];
  isLaunched?: boolean | null;
  wsdl_url?: string | null;
  queue_id?: string | null;
  channel_id?: string | null;
  incident_subject?: string;
  delay_units?: string;
  min_period_units?: string;
  account_id?: string | null;
  errorMessage?: string;
}

export interface Translation {
  code: string;
  name: string;
  updated_at: string;
  default_locale: boolean;
  missing: Record<string, string[]>;
  ready: Record<string, string[]>;
  stale: Record<string, string[]>;
}

export interface SelectedApps {
  appId: string;
  platform: Platform;
}

export const API_VERSION_WITH_INITIATOR_PROMPTS = '15';
export const API_VERSION_WITH_RICH_TEXT = '14';
export const API_VERSION_WITH_NO_RICH_TEXT_PROMPTS = '13';
export const API_VERSION_WITH_NO_RICH_TEXT_SURVEYS = '12';

export type PromptLinkAppendedVariables = 'conversation_id';

export enum PromptActionType {
  Cancel = 'cancel',
  Dismiss = 'dismiss',
  Link = 'NavigateToLink',
  Survey = 'Survey',
  MessageCenter = 'MessageCenterV2',
  NilClass = 'NilClass', // issue from WEB, when delete survey used in note action
  AlchemerSurvey = 'AlchemerSurvey',
  Initiator = 'Initiator',
}

export interface PromptAction {
  id?: string;
  label: string;
  execCount?: number;
  interactionType: PromptActionType;
  interactionId?: string;
  url?: string;
  order?: number;
  target?: 'new' | 'self';
  appendVariables?: PromptLinkAppendedVariables[];
  _destroy?: 1;
}

export interface AppInteractionData {
  interactionId?: string;
  appId: string;
  platform: Platform;
  active: boolean;
  codePoints?: InteractionItem['code_points'];
  criteria?: TargetingCriteria;
  triggeredBy?: InteractionTrigger[];
  responseCount?: number;
  responseRate?: number | null;
  responseRateCachedTime?: string | null;
  multipleResponses?: boolean; // TODO: Move this field to the root of MultiAppsSurvey in the future
  viewCount?: number;
  actions?: PromptAction[];
  archivedActions?: PromptAction[];
  minApiVersion?: number;
}
