import { AppInteractionData, InteractionType, Platform } from 'src/types/core';
import { AppMemberRole } from 'src/reducers/apps.types';
import { OrganizationApp } from 'src/reducers/organizations';
import { useMemo } from 'react';

export const getInteractionsAccess = (platform: Platform, role: AppMemberRole) => {
  const isWeb = platform === Platform.Web;
  const isAdmin = role === AppMemberRole.Admin;

  return {
    loveDialog: isAdmin,
    messageCenter: isAdmin,
    surveyStyling: isAdmin && isWeb,
    ratingDialog: isAdmin && !isWeb,
    initiator: isAdmin,
  };
};

export const doesInteractionSupportMultipleTargets = (type: InteractionType) =>
  [InteractionType.Survey, InteractionType.TextModal].includes(type);

export type SelectedPlatforms = {
  hasAndroidApps: boolean;
  hasiOSApps: boolean;
  hasWebApps: boolean;
  hasMobileApps: boolean;
};

export const selectedPlatformsFromInteractionData = (interactionData: AppInteractionData[]): SelectedPlatforms => {
  const selectedPlatforms = new Set(interactionData.map(({ platform }) => platform));

  return {
    hasAndroidApps: selectedPlatforms.has(Platform.Android),
    hasiOSApps: selectedPlatforms.has(Platform.iOS),
    hasMobileApps: selectedPlatforms.has(Platform.Android) || selectedPlatforms.has(Platform.iOS),
    hasWebApps: selectedPlatforms.has(Platform.Web),
  };
};

export const useAppsFromInteractionData = (
  apps: OrganizationApp[],
  interactionData: AppInteractionData[],
): OrganizationApp[] =>
  useMemo(() => {
    const appIds = new Set(interactionData.map(({ appId }) => appId));

    return apps.filter(({ id }) => appIds.has(id));
  }, [apps, interactionData]);

// https://github.com/apptentive/pupum/blob/07d4c802411f84251bf39a6727f61af4f9712ede/legacy/assets/apptentive.js#L404
export const substituteAppName = (text: string = '', appName: string): string => {
  return appName ? text.replace(/%{app_name}/g, appName) : text;
};
