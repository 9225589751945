import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CriteriaUnifiedTargeting } from 'src/interactions/newinteractions/targeting/multi-app-segment-builder/types';
import { GetState } from 'src/reducers';
import { getCurrentOrgId } from 'src/selectors/current_app';
import { UnifiedInteractionsService } from 'src/services/unified-interaction-service';
import { CriteriaTargeting } from 'src/types/core';

export const FETCH_TARGETING_PENDING = 'FETCH_TARGETING_PENDING';
export const FETCH_TARGETING_SUCCESS = 'FETCH_TARGETING_SUCCESS';
export const FETCH_TARGETING_FAILURE = 'FETCH_TARGETING_FAILURE';

export const fetchTargetingPending = (appId: string) => ({
  type: FETCH_TARGETING_PENDING,
  payload: { appId },
  meta: { appId },
});
export const fetchTargetingSuccess = (appId: string, json: CriteriaTargeting) => ({
  type: FETCH_TARGETING_SUCCESS,
  payload: json,
  meta: { appId },
});
export const fetchTargetingFailure = (appId: string, error: Error) => ({
  type: FETCH_TARGETING_FAILURE,
  payload: error,
  error: true,
  meta: { appId },
});

export interface TargetingApi {
  fetchTargeting: (appId: string) => Promise<CriteriaTargeting>;
}

export interface TargetingThunk {
  api: TargetingApi;
}

export const fetchTargeting =
  (appId: string) =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>, _getState: GetState, { api }: TargetingThunk): Promise<any> => {
    dispatch(fetchTargetingPending(appId));
    return api
      .fetchTargeting(appId)
      .then((json: CriteriaTargeting) => dispatch(fetchTargetingSuccess(appId, json)))
      .catch((error: Error) => dispatch(fetchTargetingFailure(appId, error)));
  };

export const FETCH_UNIFIED_TARGETING_PENDING = 'FETCH_UNIFIED_TARGETING_PENDING';
export const FETCH_UNIFIED_TARGETING_SUCCESS = 'FETCH_UNIFIED_TARGETING_SUCCESS';
export const FETCH_UNIFIED_TARGETING_FAILURE = 'FETCH_UNIFIED_TARGETING_FAILURE';

export const fetchUnifiedTargetingPending = (orgId: string, unifiedInteractionId: string) => ({
  type: FETCH_UNIFIED_TARGETING_PENDING,
  payload: { orgId, unifiedInteractionId },
  meta: { orgId, unifiedInteractionId },
});
export const fetchUnifiedTargetingSuccess = (
  orgId: string,
  unifiedInteractionId: string,
  json: CriteriaUnifiedTargeting,
) => ({
  type: FETCH_UNIFIED_TARGETING_SUCCESS,
  payload: json,
  meta: { orgId, unifiedInteractionId },
});
export const fetchUnifiedTargetingFailure = (orgId: string, unifiedInteractionId: string, error: Error) => ({
  type: FETCH_UNIFIED_TARGETING_FAILURE,
  payload: error,
  error: true,
  meta: { orgId, unifiedInteractionId },
});

export interface UnifiedTargetingApi {
  fetchUnifiedTargeting: (orgId: string, unifiedInteractionId: string) => Promise<CriteriaTargeting>;
}

export interface UnifiedTargetingThunk {
  api: UnifiedTargetingApi;
}

export const fetchUnifiedTargeting =
  (unifiedInteractionId: string) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: GetState): Promise<any> => {
    const orgId = getCurrentOrgId(getState());
    dispatch(fetchUnifiedTargetingPending(orgId, unifiedInteractionId));
    try {
      const json = await UnifiedInteractionsService.getTargeting(orgId, unifiedInteractionId);
      return dispatch(fetchUnifiedTargetingSuccess(orgId, unifiedInteractionId, json));
    } catch (error) {
      return dispatch(fetchUnifiedTargetingFailure(orgId, unifiedInteractionId, error as Error));
    }
  };
